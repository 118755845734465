.week .week-day {
    position: relative;
    display: block;
    width: calc(100% / 7);
    min-height: 150px;
    border-right: 1px solid gainsboro;
    border-bottom: 1px solid gainsboro;
}

.week .week-day:first-child {
    border-left: 1px solid gainsboro;
}

.week-day span {
    display: block;
    width: 100%;
    padding: .5rem;
    text-align: right;
}

.different-month span {
    opacity: .4;
}

.today {
    background: gainsboro;
}
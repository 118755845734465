body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.calendar-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.toggler-menu {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 1rem 0;
    list-style: none;
}
.event-creator {
    box-sizing: border-box;
    width: 200px;
    padding: 10px 15px;
    position: absolute;
    top: 60%;
    left: -23px;
    z-index: 1000;
    background: #fff;
    border: 1px solid #333;
    border-radius: 10px;
}

.event-creator input {
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid lightgrey;
}

.event-creator input:focus,
.event-creator input:active {
    outline: none;
}

.input-field {
    margin-bottom: 1rem;
    color: lightgrey;
    text-align: left;
}

.input-field label {
    margin: 0;
}

.group {
    display: flex;
    justify-content: space-between;
}

.group button {
    background: transparent;
    border: none;
    outline: none;
    color: #333;
}

.group button.cancel {
    color: red;
}

.relative {
    position: relative;
    box-sizing: border-box;
}

.relative i {
    position: absolute;
    top: 50%;
    right: 0px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%)
}
.week .week-day {
    position: relative;
    display: block;
    width: calc(100% / 7);
    min-height: 150px;
    border-right: 1px solid gainsboro;
    border-bottom: 1px solid gainsboro;
}

.week .week-day:first-child {
    border-left: 1px solid gainsboro;
}

.week-day span {
    display: block;
    width: 100%;
    padding: .5rem;
    text-align: right;
}

.different-month span {
    opacity: .4;
}

.today {
    background: gainsboro;
}
.box {
    display: block;
    width: calc(100% / 7);
    padding: .7rem 0;
    font-size: 1.05rem;
    text-align: center;
    background: gainsboro;
    color: #333;
}
.calendar-app {
    padding: 1.5rem 0;
}

.event-creator {
    box-sizing: border-box;
    width: 200px;
    padding: 10px 15px;
    position: absolute;
    top: 60%;
    left: -23px;
    z-index: 1000;
    background: #fff;
    border: 1px solid #333;
    border-radius: 10px;
}

.event-creator input {
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid lightgrey;
}

.event-creator input:focus,
.event-creator input:active {
    outline: none;
}

.input-field {
    margin-bottom: 1rem;
    color: lightgrey;
    text-align: left;
}

.input-field label {
    margin: 0;
}

.group {
    display: flex;
    justify-content: space-between;
}

.group button {
    background: transparent;
    border: none;
    outline: none;
    color: #333;
}

.group button.cancel {
    color: red;
}

.relative {
    position: relative;
    box-sizing: border-box;
}

.relative i {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%)
}